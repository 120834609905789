<header class="shadow">
	<nav class="navbar navbar-expand-lg">
		<div class="container-fluid">
			<a class="navbar-brand" [routerLink]="['/']">
				@if (!loading) {
					<img [attr.src]="user.defaultNavbarLogo.base64.prefix + user.defaultNavbarLogo.base64.data" style="height: 50px; width: 180px" />
				}
			</a>
			<button class="navbar-toggler" type="button" (click)="openCanvas(canvasContent)">
				<fa-icon [icon]="['fas', 'bars']"></fa-icon>
			</button>
			<ng-template #canvasContent let-offcanvas>
				<div class="offcanvas-header">
					<span class="offcanvas-title" id="offcanvasNavbarLabel">
						<button type="button" class="btn-close text-reset" (click)="offcanvas.close('Close click')" aria-label="Close"></button>
					</span>
				</div>
				<div class="offcanvas-body">
					<engagement-navbar-nav (navigated)="offcanvas.close('Navigated')"></engagement-navbar-nav>
				</div>
			</ng-template>
			<div class="offcanvas">
				<engagement-navbar-nav class="offcanvas-body"></engagement-navbar-nav>
			</div>
		</div>
	</nav>
	<div class="container-fluid d-none d-lg-block desktop-nav">
		<div class="row">
			<div class="col-auto">
				<a [routerLink]="['engagements']" class="nav-link" routerLinkActive="active">Engagements</a>
			</div>
			<div class="col-auto">
				<a [routerLink]="['emails']" class="nav-link" routerLinkActive="active">Emails</a>
			</div>
		</div>
	</div>
</header>

@if (isSystem && companies?.length && defaultCompany) {
	<div class="container-fluid pt-3 px-4 px-lg-5">
		<div class="row d-flex justify-content-end">
			<div class="col-auto">
				<div ngbDropdown class="compact-select">
					<label class="compact-select-label">Company</label>
					<a ngbDropdownToggle href="javascript:void(0);" id="companyFilter" type="button" aria-expanded="false">
						{{ defaultCompany.name }}
					</a>
					<div ngbDropdownMenu aria-labelledby="companyFilter">
						@for (company of companies; track company.id) {
							<a ngbDropdownItem href="javascript:void(0);" (click)="chooseDefaultCompany(company)">{{ company.name }}</a>
						}
					</div>
				</div>
			</div>
		</div>
	</div>
}
