import { Component, Injector, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { environment } from '../environments/environment';
import { ServiceLocator } from './core/auth/services';
import { AppStateService } from './core/auth/services/app-state.service';
import { FooterComponent } from './core/dashboard/components/footer/footer.component';
import { NavbarComponent } from './core/dashboard/components/navbar/navbar.component';
import { User } from './shared/models';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const CanvasJS: any;

@Component({
	selector: 'engagement-root',
	standalone: true,
	imports: [RouterOutlet, FooterComponent, NavbarComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
	public user: User;
	public appReady: boolean;

	constructor(
		injector: Injector,
		private appStateService: AppStateService,
	) {
		ServiceLocator.injector = injector;
		const origin = window.location.origin;
		environment.apiUrl = (environment.apiUrls as { [key: string]: string })[origin] || environment.apiUrl;

		CanvasJS.addColorSet('pieChartColors', ['#D5DFEC', '#214A86', '#1E91D6']);
	}

	ngOnInit(): void {
		this.appStateService.onReady.subscribe((ready: { app: boolean }) => {
			this.appReady = ready.app;
		});
	}
}
