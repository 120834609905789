import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

import _ from 'lodash';

import { EmailModuleSection } from 'src/app/shared/models';

@Pipe({
	name: 'emailModuleItemSectionOrder',
	standalone: true,
})
export class EmailModuleItemSectionOrderPipe implements PipeTransform {
	transform(
		sections: {
			moduleSection: FormControl<EmailModuleSection>;
			content: FormControl<string>;
			attachmentId: FormControl<number>;
			attachmentName: FormControl<string>;
		}[],
	): {
		moduleSection: FormControl<EmailModuleSection>;
		content: FormControl<string>;
		attachmentId: FormControl<number>;
		attachmentName: FormControl<string>;
	}[] {
		return sections?.length ? _.orderBy(sections, s => s.moduleSection.value.sortOrder) : sections;
	}
}
